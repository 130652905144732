export default[
    {
        path: '/home',
        name: '/home',
        component: () => import('@/views/dashboard/admin/index.vue'),
        meta: {
          pageTitle: 'Dashboard',
          breadcrumb: [
            {
              text: 'Dashboard',
              active: true,
            },
          ],
        },
      }, 
]