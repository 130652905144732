export default [
    {
        path: '/manajemen_bidang',
        name: '/manajemen_bidang',
        component: () => import('@/views/pu/setup/manajemen_bidang/index.vue'),
        meta: {
            pageTitle: 'Master',
            breadcrumb: [{
                    text: 'setup',
                    active: false,
                },
                {
                    text: 'Manajemen Bidang',
                    active: true,
                },

            ],
        },
    },  {
        path: '/validasi_usulan_kegiatan',
        name: '/validasi_usulan_kegiatan',
        component: () => import('@/views/pu/validasi_usulan_kegiatan/index.vue'),
        meta: {
            pageTitle: 'Master',
            breadcrumb: [{
                    text: 'Penilaian',
                    active: false,
                },
                {
                    text: 'Valisasi Pengajuan Kegiatan',
                    active: true,
                },

            ],
        },
    },
    {
        path: '/perhitungan_ruc',
        name: '/perhitungan_ruc',
        component: () => import('@/views/pu/master_perhitungan_ruc/index.vue'),
        meta: {
            pageTitle: 'Master',
            breadcrumb: [{
                    text: 'setup',
                    active: false,
                },
                {
                    text: 'Perhitungan RUC Ruas Jalan',
                    active: true,
                },

            ],
        },
    },
    {
        path: '/periode_pengajuan',
        name: '/periode_pengajuan',
        component: () => import('@/views/pu/setup/periode_pengajuan/index.vue'),
        meta: {
            pageTitle: 'Master',
            breadcrumb: [{
                    text: 'setup',
                    active: false,
                },
                {
                    text: 'Periode Pengajuan',
                    active: true,
                },

            ],
        },
    },{
        path: '/admin_bidang',
        name: '/admin_bidang',
        component: () => import('@/views/pu/admin_bidang/index.vue'),
        meta: {
            pageTitle: 'Master',
            breadcrumb: [{
                    text: 'setup',
                    active: false,
                },
                {
                    text: 'Admin Bidang',
                    active: true,
                },

            ],
        },
    },
    //
    {
        path: '/kode_akun_kegiatan',
        name: '/kode_akun_kegiatan',
        component: () => import('@/views/pu/kode_akun_kegiatan/index.vue'),
        meta: {
            pageTitle: 'Master',
            breadcrumb: [{
                    text: 'setup',
                    active: false,
                },
                {
                    text: 'Master Kode Akun Kegiatan',
                    active: true,
                },

            ],
        },
    },
    {
        path: '/master_indikator_kegiatan',
        name: '/master_indikator_kegiatan',
        component: () => import('@/views/pu/master_indikator_kegiatan/index.vue'),
        meta: {
            pageTitle: 'Master',
            breadcrumb: [{
                    text: 'setup',
                    active: false,
                },
                {
                    text: 'Master Indikator Kegiatan',
                    active: true,
                },

            ],
        },
    },
    {
        path: '/komponen_penilaian',
        name: '/komponen_penilaian',
        component: () => import('@/views/pu/komponen_penilaian/index.vue'),
        meta: {
            pageTitle: 'Master',
            breadcrumb: [{
                    text: 'setup',
                    active: false,
                },
                {
                    text: 'Komponen penilaian',
                    active: true,
                },

            ],
        },
    },
    //
    {
        path: '/tahun_anggaran',
        name: '/tahun_anggaran',
        component: () => import('@/views/pu/setup/tahun_anggaran/index.vue'),
        meta: {
            pageTitle: 'Master',
            breadcrumb: [{
                    text: 'setup',
                    active: false,
                },
                {
                    text: 'Tahun Anggaran',
                    active: true,
                },

            ],
        },
    },
    {
        path: '/periode_renstra',
        name: '/periode_renstra',
        component: () => import('@/views/pu/setup/periode_renstra/index.vue'),
        meta: {
            pageTitle: 'Master',
            breadcrumb: [{
                    text: 'setup',
                    active: false,
                },
                {
                    text: 'Perode Renstra',
                    active: true,
                },

            ],
        },
    },
    
    {
        path: '/manajemen_user',
        name: '/manajemen_user',
        component: () => import('@/views/pu/setup/manajemen_user/index.vue'),
        meta: {
            pageTitle: 'Master',
            breadcrumb: [{
                    text: 'Manajemen User',
                    active: false,
                },
              

            ],
        },
    },{
        path: '/laporan',
        name: '/laporan',
        component: () => import('@/views/pu/laporan/index.vue'),
        meta: {
            pageTitle: 'Master',
            breadcrumb: [{
                    text: 'Laporan',
                    active: false,
                },
               

            ],
        },
    } ,{
        path: '/kegiatan_renstra',
        name: '/kegiatan_renstra',
        component: () => import('@/views/pu/master_kegiata/kegiatan_renstra/index.vue'),
        meta: {
            pageTitle: 'Master',
            breadcrumb: [{
                    text: 'Renstra',
                    active: false,
                },
               

            ],
        },
    },
    {
        path: '/ruc',
        name: '/ruc',
        component: () => import('@/views/pu/master_kegiata/ruc/index.vue'),
        meta: {
            pageTitle: 'Master',
            breadcrumb: [{
                    text: 'RUC',
                    active: false,
                },
                

            ],
        },
    },
    {
        path: '/renja',
        name: '/renja',
        component: () => import('@/views/pu/renja/index.vue'),
        meta: {
            pageTitle: 'Master',
            breadcrumb: [
                {
                    text: 'RENJA',
                    active: false,
                }, 
                

            ],
        },
    }, 
    
    {
        path: '/renja_set_up',
        name: '/renja_set_up',
        component: () => import('@/views/pu/renja/setup/index.vue'),
        meta: {
            pageTitle: 'Master',
            breadcrumb: [{
                    text: 'RENJA',
                    active: false,
                },{
                    text: 'Setup Renja Tahunan',
                    active: false,
                },
                

            ],
        },
    },
    
    {
        path: '/renja_riport',
        name: '/renja_riport',
        component: () => import('@/views/pu/renja/riport/index.vue'),
        meta: {
            pageTitle: 'Master',
            breadcrumb: [{
                    text: 'RENJA',
                    active: false,
                },{
                    text: 'Laporan Renja Tahunan', 
                },
                

            ],
        },
    },{
        path: '/renja_laporan_bidang_bale',
        name: '/renja_laporan_bidang_bale',
        component: () => import('@/views/pu/renja/balai_bidang/index.vue'),
        meta: {
            pageTitle: 'Master',
            breadcrumb: [{
                    text: 'RENJA',
                    active: false,
                },{
                    text: 'Laporan Renja Balai Bidang', 
                },
                

            ],
        },
    },{
        path: '/penilaian_kegiatan',
        name: '/penilaian_kegiatan',
        component: () => import('@/views/pu/penilaian_kegiatan/index.vue'),
        meta: {
            pageTitle: 'Master',
            breadcrumb: [{
                    text: 'Penilaian Kegiatan',
                    active: false,
                },
                

            ],
        },
    },{
        path: '/nilai_tambah_ruas_jalan',
        name: '/nilai_tambah_ruas_jalan',
        component: () => import('@/views/pu/nilai_tambah_ruas/index.vue'),
        meta: {
            pageTitle: 'Master',
            breadcrumb: [{
                    text: 'Nilai Tambah Ruas Jalan',
                    active: false,
                },
                

            ],
        },
    },
    {
        path: '/kegiatan_apbn',
        name: '/kegiatan_apbn',
        component: () => import('@/views/pu/kegiatan_apbn/index.vue'),
        meta: {
            pageTitle: 'Master',
            breadcrumb: [{
                    text: 'Pengajuan Kegiatan Suber Dana APBN',
                    active: false,
                },
                

            ],
        },
    },
    {
        path: '/ruas_jalan_master',
        name: '/ruas_jalan_master',
        component: () => import('@/views/pu/ruas_jalan/master/index.vue'),
        meta: {
            pageTitle: 'Master',
            breadcrumb: [{
                    text: 'Ruas Jalan',
                    active: false,
                },{
                    text: 'Master Ruas Jalan',
                    active: false,
                },
                

            ],
        },
    }, {
        path: '/kondisi_ruas_jalan',
        name: '/kondisi_ruas_jalan',
        component: () => import('@/views/bpj/ruas_jalan/kondisi/index.vue'),
        meta: {
            pageTitle: 'Master',
            breadcrumb: [{
                    text: 'Ruas Jalan',
                    active: false,
                },{
                    text: 'Kondisi Ruas Jalan',
                    active: false,
                },
                

            ],
        },
    },{
        path: '/ruas_jalan_validasi',
        name: '/ruas_jalan_validasi',
        component: () => import('@/views/pu/ruas_jalan/validasi_pengajuan/index.vue'),
        meta: {
            pageTitle: 'Master',
            breadcrumb: [{
                    text: 'Ruas Jalan',
                    active: false,
                },{
                    text: 'Validasi Pengauan Pendataan',
                    active: false,
                },
                

            ],
        },
    },
    {
        path: '/usulan_kegiatan',
        name: '/usulan_kegiatan',
        component: () => import('@/views/bpj/ajuan_kegiatan/usulan_kegiatan/index.vue'),
        meta: {
            pageTitle: 'Master',
            breadcrumb: [{
                    text: 'Ajuan Kegiatan',
                    active: false,
                },{
                    text: 'Usulan Kegiatan',
                    active: false,
                },
                

            ],
        },
    },{
        path: '/revisi_usulan_kegiatan',
        name: '/revisi_usulan_kegiatan',
        component: () => import('@/views/bpj/ajuan_kegiatan/revisi_usulan_kegiatan/index.vue'),
        meta: {
            pageTitle: 'Master',
            breadcrumb: [{
                    text: 'Ajuan Kegiatan',
                    active: false,
                },{
                    text: 'Revisi Usulan Kegiatan',
                    active: false,
                },
                

            ],
        },
    },{
        path: '/input_kontrak',
        name: '/input_kontrak',
        component: () => import('@/views/pu/input_kontrak/index.vue'),
        meta: {
            pageTitle: 'Master',
            breadcrumb: [{
                    text: 'Input Kontrak',
                    active: false,
                }, 
                

            ],
        },
    }, {
        path: '/rekap_pagu_balai',
        name: '/rekap_pagu_balai',
        component: () => import('@/views/pu/laporan_anggaran/rekap_pagu_balai/index.vue'),
        meta: {
            pageTitle: 'Cetak Laporan',
            breadcrumb: [{
                    text: 'Cetak Laporan',
                    active: false,
                }, {
                    text: 'Balai / Bidang',
                    active: true,
                }, 
                

            ],
        },
    }, {
        path: '/rekap_pagu_program',
        name: '/rekap_pagu_program',
        component: () => import('@/views/pu/laporan_anggaran/rekap_pagu_program/index.vue'),
        meta: {
            pageTitle: 'Cetak Laporan',
            breadcrumb: [{
                    text: 'Cetak Laporan',
                    active: false,
                }, {
                    text: 'Program',
                    active: true,
                }, 
                

            ],
        },
    }, {
        path: '/rekap_anggaran_tu',
        name: '/rekap_anggaran_tu',
        component: () => import('@/views/pu/laporan_anggaran/rekap_anggaran_tu/index.vue'),
        meta: {
            pageTitle: 'Cetak Laporan',
            breadcrumb: [{
                    text: 'Cetak Laporan',
                    active: false,
                }, {
                    text: ' Rekapitulasi Tata Usaha',
                    active: true,
                }, 
                

            ],
        },
    }, {
        path: '/rekap_anggaran_sub_kegiatan',
        name: '/rekap_anggaran_sub_kegiatan',
        component: () => import('@/views/pu/laporan_anggaran/rekap_anggaran_sub_kegiatan/index.vue'),
        meta: {
            pageTitle: 'Cetak Laporan',
            breadcrumb: [{
                    text: 'Cetak Laporan',
                    active: false,
                }, {
                    text: 'Rekapitulasi Sub Kegiatan',
                    active: true,
                }, 
                

            ],
        },
    }, {
        path: '/rekap_anggaran_kab_kota',
        name: '/rekap_anggaran_kab_kota',
        component: () => import('@/views/pu/laporan_anggaran/rekap_anggaran_kab_kota/index.vue'),
        meta: {
            pageTitle: 'Cetak Laporan',
            breadcrumb: [{
                    text: 'Cetak Laporan',
                    active: false,
                }, {
                    text: 'Rekapitulasi Kabupaten dan Kota',
                    active: true,
                }, 
                

            ],
        },
    }, {
        path: '/rekap_kontrak',
        name: '/rekap_kontrak',
        component: () => import('@/views/pu/laporan_anggaran/input_kontrak/index.vue'),
        meta: {
            pageTitle: 'Cetak Laporan',
            breadcrumb: [{
                    text: 'Cetak Laporan',
                    active: false,
                }, {
                    text: 'Rekapitulasi Kontrak',
                    active: true,
                }, 
                

            ],
        },
    },

]